.login-content {
  height: 80vh;
}
.middle-center {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ant-tabs-tab{
  font-size: 16px;
}