.trigger {
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
  padding-left: 10px;
  color: #fff;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.3);
  margin: 16px;
  text-align: center;
  font-weight: bold;
}
.listItem {
  width: 8vw;
  position: relative;
}
.listItem p {
  position: absolute;
  left: 30%;
  top: 50%;
  transform: translate(0, -50%);
}

.user {
  transition: color 0.5s;
  font-style: "italic";
  color: #ffffff;
}
.user:hover,
.listItem:hover {
  color: #1890ff;
}
.items {
  position: absolute;
  opacity: 0;
  transform: translateY(100%);
}
.user:hover .items {
  opacity: 1;
  transform: translateY(0);
}

.layout {
  background-color: #eeeeee;
}
.submenu {
  height: 80px;
}

/* 顶部菜单 */
#mainMenu span {
  text-align: center;
  color: #555;
  font-size: 18px;
  font-weight: normal;
}

/* 侧边栏 */
#sidelist {
  width: 300px;
}
#sidelist > li {
  position: relative;
  margin-bottom: 30px;
}
/* 侧边栏未选中样式 */
#sidelist > li div {
  width: 220px;
  height: 60px;
  background: #fff;
  transform: skewx(-30deg) translatex(-100px);
}
#sidelist > li span {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
}
/* 侧边栏选中样式 */
#sidelist > li.selected div {
  width: 300px;
  height: 60px;
  background: #005ca2;
  transform: skewx(-30deg) translatex(-100px);
}
#sidelist > li.selected span {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  text-align: center;
  font-size: 16px;
}
@media (max-width: 768px) {
  .ant-layout-sider {
    display: none !important;
  }
}
/* @media (min-width: 768px) and (max-width: 992px) {
  #sidelist > li div {
    width: 150px;
    height: 40px;
  }
  #sidelist > li span {
    width: 40px;
    font-size: 10px;
  }
  #sidelist > li.selected div {
    width: 180px;
    height: 40px;
  }
  #sidelist > li.selected span {
    width: 60px;
    font-size: 10px;
  }
  .Sider div {
    width: 300px;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  #sidelist > li div {
    width: 190px;
  }
  #sidelist > li span {
    width: 80px;
  }
  #sidelist > li.selected div {
    width: 240px;
  }
  #sidelist > li.selected span {
    width: 100px;
  }
} */
